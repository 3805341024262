import React, { useEffect, useState, useContext } from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Location } from '@reach/router';
import queryString from 'query-string';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import ButtonLink from '../../components/shared/ButtonLink';
import { flex, wrapper } from '../../utils/style';
import { freespinActivation } from '../../utils/api/freespin';
import { setToken, setUserName } from '../../utils/api';
import { setUser } from '../../store/actions';
import { StoreContext } from '../../store';

const Wrapper = styled('div')`
  ${wrapper};
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }

  & a {
    transition: 0s;
    width: 270px;
    height: 40px;
    background-color: #5a5f79;
    ${flex};

    &:hover {
      background-color: #737692;
    }

    &:active {
      opacity: 0.8;
    }
  }
`;

const withLocation = (ComponentToWrap) => (props) => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
);

const ActivationUserTemplate = ({ data, search, navigate }) => {
  const [state, dispatch] = useContext(StoreContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  if (search && Object.keys(search).length === 3) {
    freespinActivation(search.email, search.code).then((json) => {
      if (json.error) {
        setError(json.message);
        return false;
      }

      setToken(json.token);
      setUserName(json.username);
      setUser(dispatch, json.username);
      setSuccess(true);
    });
  }

  useEffect(() => {
    if (success || (search && search.action !== 'activation')) {
      navigate('/');
    }
  }, [success]);

  return (
    <>
      {error && (
        <Layout>
          <SEO title="Account Activation" description="Info on your s-bobet.com account activation" />
          <Wrapper>
            <div className="flex">
              <div className="col-2">
                {data.missing.childImageSharp.fluid ? (
                  <Img
                    alt="GCLUB Activation"
                    fluid={data.missing.childImageSharp.fluid}
                    fadeIn={false}
                    loading="eager"
                  />
                ) : (
                  'GCLUB Activation Progress'
                )}
              </div>
              <div className="col-2">
                <h1>การเปิดใช้งานการแจ้งเตือน:</h1>
                <p>{error}</p>
                <ButtonLink
                  to="/"
                  text="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"
                  ariaText="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"
                />
              </div>
            </div>
          </Wrapper>
        </Layout>
      )}

      {!error && !success && (
        <Layout>
          <Wrapper>
            <div className="flex">
              <div>
                <h1>เปิดใช้งานบัญชี...</h1>
              </div>
            </div>
          </Wrapper>
        </Layout>
      )}
    </>
  );
};
export default withLocation(ActivationUserTemplate);

export const pageQuery = graphql`
  query {
    missing: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
